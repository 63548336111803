import { render, staticRenderFns } from "./NotInfo.vue?vue&type=template&id=19e860d8&scoped=true"
import script from "./NotInfo.vue?vue&type=script&lang=ts"
export * from "./NotInfo.vue?vue&type=script&lang=ts"
import style0 from "./NotInfo.vue?vue&type=style&index=0&id=19e860d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e860d8",
  null
  
)

export default component.exports