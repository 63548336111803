
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipDurumu, TakipDurumuLabel} from "@/enum/TakipDurumu";
import TakipListFilterForm from "@/components/takip/form/TakipListFilterForm.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {VekaletEntity} from "@/entity/VekaletEntity";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import AlacakKopyalaForm from "@/components/forms/AlacakKopyalaForm.vue";
import TakipKopyalaForm from "@/components/forms/TakipKopyalaForm.vue";

interface Options {
  sortBy: string[];
  sortDesc: boolean[];
  page: number;
  itemsPerPage: number;
}
@Component({
  components: {
    TakipKopyalaForm,
    AlacakKopyalaForm,
    FormDialog,
    TakipListFilterForm,
    DeleteDialog,
  }
})
export default class TakipList extends Vue {
  @Prop({default: false}) showDetailedSearch!: boolean;
  @Prop({default: false}) muvekkilTakipList !: boolean;
  @Prop() propItems !: Array<TakipEntity>;

  items: Array<TakipEntity> | null = [];
  filterLineVisible = false
  takipDurumuLabel = TakipDurumuLabel;
  checkboxesShow = false

  currentPage: number = 2
  showAll=false
  scrollTimeout: any;
  showAllProgress = false
  itemsLenght = 0;
  isLoading = true;
  options: Options = { sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10 };

  allCheckbox=false
  takipCheckbox: boolean[] = [];

  filters: any = {
    borcluAdSoyad: null,
    borcluTcKimlik: null,
    alacakliTcKimlik: null,
    borcluVergiKimlik: null,
    alacakliVergiKimlik: null,
    icraDairesi: null,
    dosyaEsasNo: null,
    alacakliAdSoyad: null,
    takipDurumu: null,
    ilamEsasNo: null,
    ilamKararNo: null,
    kartNo: null,
    icraProKartNo: null,
    mtsEsasNo: null,
    tuketiciKodu: null,
    kurumDosyaNo: null,
    takipTipi: null,
    keyword: null
  };
  oldFilters = {};
  oldOptions = {};
  sessionStorage = JSON.parse(sessionStorage.user)

  headers = [
    {text: '', align: 'start', sortable: false, value: 'checkbox' },
    {text: "Kart No", align: "center", sortable: true, value: "id", class: 'my-header-style'},
    {text: "Borçlu Ad Soyad", value: "borclu_ad", sortable: false, align: "center", class: 'my-header-style'},
    {text: "Alacaklı Ad Soyad", value: "alacakli_ad", sortable: false, align: "center", class: 'my-header-style'},
    {text: "Takip Tipi", value: "takip_tipi", sortable: false, align: "center", class: 'my-header-style'},
    {text: "İcra Dairesi", value: "icra_dairesi", sortable: false, align: "center", class: 'my-header-style'},
    {text: "Dosya Esas No", value: "dosya_esas_no", sortable: true, align: "center", class: 'my-header-style'},
    {text: "Takip Durumu", value: "takip_durumu", sortable: true, align: "center", class: 'my-header-style'},
    {text: "Takip Tarihi", value: "takip_tarihi", sortable: true, align: "center", class: 'my-header-style'},
    {text: "İşlemler", value: "actions", sortable: false, align: "center", width: 150, class: 'my-header-style'}
  ];

  showAllItems(){
    this.showAll=!this.showAll;
  }
  handleScroll(event: any) {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      const scrollPosition = event.target.scrollTop + event.target.offsetHeight;
      const tableHeight = event.target.scrollHeight;
      if (scrollPosition >= tableHeight * 0.99) {
        this.loadMore();
      }
    }, 500);
  }
  async loadMore() {
    this.showAllProgress =true
    this.$http.get('/api/v1/takip/filter', {
      params: {
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0],
        page: this.currentPage,
        itemsPerPage: this.options.itemsPerPage,
      }
    }).then((response) => {
      if (response.data.length > 0) {
        const newItems = response.data.filter((item:any) => !this.items.find(i => i.id === item.id));
        this.items = [...this.items, ...newItems];
        this.currentPage++;
      }
    }).finally(()=>{
      this.showAllProgress = false
    })
  }

  icraDairesiIsim(item:TakipEntity) {
    if (!item.icra_dairesi && item.ozel_icra_dairesi_isim) {
      return item.ozel_icra_dairesi_isim;
    } else if (item.icra_dairesi && !item.ozel_icra_dairesi_isim) {
      return item.icra_dairesi.isim;
    } else if (!item.icra_dairesi && !item.ozel_icra_dairesi_isim) {
      return '';
    }
  }

  detay(item: TakipEntity) {
    let filteredTakip= localStorage.getItem('filteredTakip')
    if (filteredTakip != null) {
      let takipler = JSON.parse(filteredTakip);
      takipler.index = takipler.data.indexOf(item.id);
      localStorage.setItem('filteredTakip', JSON.stringify(takipler));
    }
    if (item.takip_durumu === TakipDurumu.acilmamis) {
      this.$router.push(ProgramPaths.icrapro + '/takip-ekle/' + item.id);
    } else {
      this.$router.push(ProgramPaths.icrapro + '/takip/' + item.id);
    }
  }
  @Watch('allCheckbox')
  AllSelectCheckbox(){
    if (this.allCheckbox){
      this.takipCheckbox = this.propItems.map(item => true);
    }else{
      this.takipCheckbox = this.propItems.map(item => false);
    }
  }
    @Watch('options')
    optionsControl(){
    this.load()
    }

  load(isLoad: boolean = false) {
    if (!this.propItems) {
      let filtre: any = {}
      Object.keys(this.filters).forEach(key => (this.filters[key] != null && this.filters[key] != '') ? (filtre[key] = this.filters[key]) : null)
      if (JSON.stringify(filtre) != JSON.stringify(this.oldFilters))
        this.options.page = 1;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      if (JSON.stringify(filtre) != JSON.stringify(this.oldFilters) || JSON.stringify(this.options) != JSON.stringify(this.oldOptions) || isLoad === true) {
        this.oldFilters = {...filtre};
        this.oldOptions = {...this.options};
        this.isLoading = true;
        this.$http.get('/api/v1/takip/filter', {
          params: {
            sortBy: sortBy[0],
            sortDesc: sortDesc[0],
            page: page,
            itemsPerPage: itemsPerPage,
            ...filtre
          }
        }).then((response) => {
          this.items = response.data;
          let aktarVarMi:boolean=false
          this.items?.forEach((aktar:any)=>{
            if (aktar.aktar_takip_kart_no){
              aktarVarMi = true
            }
          })
          const sutunKontrol = this.headers.some(header => header.value === "aktar_takip_kart_no");

          if (!sutunKontrol && (this.sessionStorage.aktar_mi == 1 || aktarVarMi)){
            this.headers.splice(2, 0, {text: "İcra Pro Kart No", align: "center", sortable: true, value: "aktar_takip_kart_no", class: 'my-header-style'});
          }

          //@ts-ignore
          this.itemsLenght = response.total;
          let idler: Array<number> = [];
          response.data.forEach((takip: TakipEntity) => (idler.push(takip.id)));
          let filteredTakip = {index: 0, data: idler};
          localStorage.setItem('filteredTakip', JSON.stringify(filteredTakip));
          this.isLoading = false;
        })
      }
    }
    this.$emit("load");
  }

  // async syncUyapDosya(takip: any) {
  //   let defaultClass = this.$refs['icon' + takip.uyap_dosya_id].$el.classList.value;
  //   this.$refs['icon' + takip.uyap_dosya_id].$el.classList.value += ' mdi-spin'
  //   this.$refs['icon' + takip.uyap_dosya_id].$el.style.color = 'orange'
  //   let takipData = (await this.$http.get('/api/v1/takip/filter?uyap_dosya_id=' + takip.uyap_dosya_id)).data[0];
  //   await this.$store.dispatch('senkronizeEt', takipData);
  //   this.$refs['icon' + takip.uyap_dosya_id].$el.style.color = 'green'
  //   this.$refs['icon' + takip.uyap_dosya_id].$el.classList.value = defaultClass;
  // }

  mounted() {
    this.load();
  }
}
