

import {Component, Mixins, Ref, Vue, Watch} from "vue-property-decorator";
import GenelIslemFormWizard, {GenelIslemEntity} from "@/components/forms/form-wizard/eicrapro/GenelIslemFormWizard.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import AvukatTalepPicker from "@/components/pickers/uyap/AvukatTalepPicker.vue";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import {UyapTebligatEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapTebligatEvrakTuru";
import {ITask, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import {VForm} from "@/types";
import BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm.vue";
import IcraOdemeEmrininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/IcraOdemeEmrininTebligeCikartilmasiTalepEvrakForm.vue";
import KiymetTakdirininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/KiymetTakdirininTebligeCikartilmasiTalepEvrakForm.vue";
import TaahhuduKabulMuhtirasiTebligiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/TaahhuduKabulMuhtirasiTebligiTalepEvrakForm.vue";
import TemlikBilgisininBildirilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/TemlikBilgisininBildirilmesiTalepEvrakForm.vue";
import YenilemeEmrininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/YenilemeEmrininTebligeCikartilmasiTalepEvrakForm.vue";
import YuzUcDavetiyesininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/YuzUcDavetiyesininTebligeCikartilmasiTalepEvrakForm.vue";
import {
  TopluHacizHazirlaTaskHandler
} from "@/plugins/uyap-plugin/store/modules/TaskHandler/TopluHacizHazirlaTaskHandler";
import {ProgramPaths} from "@/enum/ProgramPaths";
import NewFormDialog from "@/components/dialogs/NewFormDialog.vue";
import NewFormWrapper from "@/components/NewFormWrapper.vue";
import {VarsayilanTebligat} from "@/classes/uyapTalep/VarsayilanTebligat";
import TopluHacizTalepDuzenleForm from "@/components/eicrapro/TopluHacizTalepDuzenleForm.vue";
import TopluTebligatDuzenleForm from "@/components/eicrapro/TopluTebligatDuzenleForm.vue";

@Component({
  components: {
    TopluTebligatDuzenleForm,
    TopluHacizTalepDuzenleForm,
    NewFormWrapper, NewFormDialog,
    YuzUcDavetiyesininTebligeCikartilmasiTalepEvrakForm,
    YenilemeEmrininTebligeCikartilmasiTalepEvrakForm,
    TemlikBilgisininBildirilmesiTalepEvrakForm,
    TaahhuduKabulMuhtirasiTebligiTalepEvrakForm,
    KiymetTakdirininTebligeCikartilmasiTalepEvrakForm,
    IcraOdemeEmrininTebligeCikartilmasiTalepEvrakForm,
    BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm,
    PersonelPicker, AvukatTalepPicker, GenelIslemFormWizard
  }
})
export default class TopluTebligatHazirlaFormWizard extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: VForm;
  @Ref('genelIslemForm') genelIslemForm!: GenelIslemFormWizard;

  localValue: GenelIslemEntity = new GenelIslemEntity();
  taskDurum = TaskDurum;
  topluTebligatDurum = TopluTebligatDurum;
  durum: TopluTebligatDurum = this.topluTebligatDurum.bosta;
  tebligatEvrakTuru = UyapTebligatEvrakTuru;
  valid = false;
  onFly = false;
  hideNext = false;
  talepler: Array<AvukatTalepListCevapRow> = [];
  message = "";

  stepperHeaders = [
    "Listelenecek Takipler",
    "Takipleri Seç",
    "Tebligatları Hazırla",
    "Tebligatları Gönder",
    "İşlemler Sıraya Alındı"
  ];
  secilenHeaders = [
    {text: "Birim Adı", value: "birimAdi"},
    {text: "Dosya Esas No", value: "dosyaNo"},
    {text: "Açılış Tarihi", value: "dosyaAcilisTarihi"},
    {text: "Dosya Durumu", value: "dosyaDurumAciklama"}
    /*{text: "Durum", value: "status", align: 'center'}*/
  ];
  talepDuzenleHeaders = [
    {text: "", value: "select", sortable: false, width: "50"},
    {text: "Dosya", value: "dosya"},
    {text: "Borçlu", value: "borclu"},
    {text: "İşlemler", value: "actions", width: "50", sortable: false}
  ];
  varsayilanTalepler = new VarsayilanTebligat();

  get showTalepAyar(): boolean {
    let talepAyarItems = [
      this.tebligatEvrakTuru.bakiye_borc_muhtirasinin_teblige_cikartilmasi,
      this.tebligatEvrakTuru.icra_odeme_emrinin_teblige_cikartilmasi
    ];
    let show = false;
    for (const talep of this.talepler) {
      if (talepAyarItems.includes(talep.talepKodu)) {
        show = true;
        break;
      }
    }
    return show;
  }

  @Watch('localValue.secilen_takipler')
  async onSecilenTakiplerChange() {
    let secilenTakip: any;
    for (secilenTakip of this.localValue.secilen_takipler) {
      if (!secilenTakip.task) {
        secilenTakip.task = new Task();
        secilenTakip.task.dosya_esas_no = secilenTakip.dosyaNo;
        secilenTakip.task.birim_adi = secilenTakip.birimAdi;
        secilenTakip.task.task_type_id = TaskTuru.toplu_haciz_hazirla;
        secilenTakip.task.dosya_id = secilenTakip.dosyaId;
        this.input();
      }
    }
  }

  onStepChange(stepNo: number) {
    if (stepNo == 3)
      this.hideNext = true;
    else
      this.hideNext = false;
  }

  onTalepChange() {
    this.localValue.secilen_takipler.forEach(takip => {
      if (takip.task && (takip.task.status == this.taskDurum.BITTI_BASARILI || takip.task.status == this.taskDurum.BITTI_HATALI))
        takip.task.status = this.taskDurum.SIRADA;
    })
    this.input();
  }

  setVarsayilanTalep(talepKod: number) {
    let talep = this.talepler.find(talepItem => talepItem.talepKodu == talepKod) as AvukatTalepListCevapRow;
    for (let takip of this.localValue.secilen_takipler) {
      for (let borclu of takip.task.response.borclular) {
        borclu = this.varsayilanTalepler.setVarsayilanTalepler(borclu,[talep], takip.dosyaId);
      }
    }
  }

  async createTebligatHazirlaTask() {
    if (this.form.validate()) {
      this.onFly = true;
      this.durum = this.topluTebligatDurum.hazirlaniyor;
      this.message = "Talepler hazırlanıyor..."
      for (let [index, takip] of Object.entries(this.localValue.secilen_takipler)) {
        // @ts-ignore
        let task: ITask = takip.task;
        if (!task.status) {
          task.status = TaskDurum.SIRADA;
        }
        try {
          this.message = (Number(index) + 1) + ". dosyanın talepleri hazırlanıyor.";
          if (task.status == TaskDurum.SIRADA) {
            task.data = {hacizParams: {}, tebligatParams: this.talepler};
            let handler = new TopluHacizHazirlaTaskHandler();
            let taskResult = await handler.handle(task);
            task = taskResult;
            task.status = this.taskDurum.BITTI_BASARILI;
          }
          this.message = Number(index + 1) + ". dosyanın varsayılan talepleri uygulanıyor."
          for (let borclu of task.response.borclular) {
            borclu.selected = true;
            borclu = await this.varsayilanTalepler.setVarsayilanTalepler(borclu, this.talepler, takip.dosyaId);
          }
          this.message = Number(index + 1) + ". dosyanın talepleri hazır."
        } catch (err) {
          task.status = this.taskDurum.BITTI_HATALI;
          this.message = Number(index + 1) + ". dosyanın talepleri hazırlanırken hata oluştu. (" + err + ")"
          task.description = err;
        } finally {
          this.input();
        }
      }
      this.durum = this.topluTebligatDurum.hazir;
      this.genelIslemForm.nextStep();
      this.onFly = false;
    }
  }

  showTalep(talepKod: UyapTebligatEvrakTuru): boolean {
    return !!this.talepler.find((talep: AvukatTalepListCevapRow) => (talep.talepKodu == talepKod));
  }

  async createTask() {
    this.onFly = true;
    let borcluSecildiMi = false;
    let tasks: Array<Task> = [];
    let takip: any;
    for (takip of this.localValue.secilen_takipler.filter(x=>x.task.status == this.taskDurum.BITTI_BASARILI)) {
      for (let borclu of takip.task.response.borclular) {
        if (borclu.selected) {
          borcluSecildiMi = true;
          let task: Task = new Task();
          task.dosya_esas_no = takip.dosyaNo;
          task.birim_adi = takip.birimAdi;
          task.dosya_id = takip.dosyaId;
          task.birim_id = takip?.birimId;
          task.uyap_dosya_acilis_tarihi = takip.dosyaAcilisTarihi ? new Date(this.$uyapHelper.formatDate(takip.dosyaAcilisTarihi)).toString() : "";
          task.task_type_id = TaskTuru.toplu_tebligat_hazirla;
          task.data = {
            dosya: takip.task.response.dosya,
            params: takip.task.response.params,
            borclu: borclu
          };
          tasks.push(task);
        }
      }
    }
    if (borcluSecildiMi) {
      await this.$store.dispatch('postTasks', tasks);
      await this.$router.push(ProgramPaths.eicrapro + '/islemler');
      this.genelIslemForm.activeStep++;
    } else
      this.$toast.warning('Talep gönderilecek borçlu seçiniz.')
    this.onFly = false;
  }
}

enum TopluTebligatDurum {
  bosta = 1,
  hazirlaniyor = 2,
  hazir = 3
}
