
import {Component, Mixins, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipForm from "@/components/forms/TakipForm.vue";
import Dates from "@/components/inputs/Dates.vue";
import {mask} from "vue-the-mask";
import {EkAlanTuru} from "@/enum/EkAlanTuru";
import {TakipDurumu} from "@/enum/TakipDurumu";

@Component({
  directives: {
    mask
  },
  components: {
    FormDialog,
    TakipForm,
    Dates,
  }
})
export default class TakipInfo extends Mixins(ObjectInputMixin) {
  details = true;
  show: boolean = false;
  EkAlanTuru = EkAlanTuru;
  TakipDurumu = TakipDurumu;

  get icraIsim(){
    let icraDairesi:string=''
    if (!this.localValue.icra_dairesi_id && this.localValue.ozel_icra_dairesi_isim){
      icraDairesi = this.localValue.ozel_icra_dairesi_isim
    }else if(!this.localValue.icra_dairesi_id && !this.localValue.ozel_icra_dairesi_isim){
      icraDairesi = 'İcra Dairesi Seçilmemiş'
    }else if(this.localValue.icra_dairesi_id){
      icraDairesi = this.localValue.icra_dairesi.isim
    }
    return icraDairesi
  }

  load() {
    this.$emit('load');
    this.$emit('kapaliToAcik')

  }
}
