
import { Component, Mixins, Prop } from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import NewFormWrapper from "@/components/NewFormWrapper.vue";
import Crypto from "crypto";

@Component({
  components: { NewFormWrapper, DeleteDialog }
})
export default class DayanakList extends Mixins(SimpleInputMixin) {
  @Prop() takipAc!: boolean | string;
  isReady = false;
  showPreview = false;
  previewData = "";
  selectedFiles: Array<File> = [];

  headers = [
    { text: 'Evrak Adı', value: 'name' },
    { text: 'İşlemler', value: 'actions', sortable: false, width: '100' }
  ]

  get isTakipAc() {
    return this.takipAc || this.takipAc === "";
  }

  async mounted() {
    if (this.isTakipAc) {
      await this.loadTakipAcData();
    } else {
      this.isReady = true;
    }
  }

  async loadTakipAcData() {
    this.isReady = false;
    for (let dayanak of this.localValue) {
      dayanak.dosya = await this.$helper.getBlobFromLink('/api/v1/file/download/' + dayanak.file_path.replace('uploads/', ''));
      dayanak.mimeType = dayanak.dosya.type;
      let base64: any = await this.$helper.convertToBase64(dayanak.dosya);
      dayanak.data = (base64.split(','))[1];
    }
    this.input();
    this.isReady = true;
  }

  deleteDayanak(index: number) {
    this.localValue.splice(index, 1);
  }

  fileRule(dosyalar: Array<File>) {
    if (dosyalar && dosyalar.length > 0) {
      for (const dosya of dosyalar) {
        let uzanti = dosya.name.split('.').pop()?.toLowerCase();
        if (["udf", "pdf", "jpg", "jpeg", "png", "tif", "tiff", "UDF", "PDF", "JPG", "JPEG", "PNG", "TIF", "TIFF"].indexOf(uzanti ?? '') >= 0) {
          if (dosya.size < 10485760)
            return true;
          else
            return "Doküman boyutu 10MB'dan fazla olamaz.";
        } else
          return "Doküman formatı UYAP'a uygun değil."
      }
    } else {
      return 'Lütfen bu alanı doldurun.';
    }
  }

  async handleFileChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      this.selectedFiles = Array.from(files);
      await this.uploadDayanak(this.selectedFiles);
    }
  }

  async uploadDayanak(files: File[]) {
    if (!files || files.length === 0) return;

    let filesValue: Array<{ data: string, name: string, mimeType: string, dosya: File | null, hash: string | null }> = []
    for (const file of files) {
      let base64File: string = await this.$helper.convertToBase64(file);
      let hash = Crypto.createHash('sha1').update((base64File.split(','))[1]).digest().toString('hex');
      let fileValue = {
        data: (base64File.split(','))[1],
        name: file.name,
        mimeType: file.type,
        dosya: file,
        hash: hash
      }
      filesValue.push(fileValue)
    }
    this.addDayanak(filesValue);
    this.selectedFiles = []; // Clear the file input after upload
  }

  addDayanak(dayanaklar: Array<any>) {
    dayanaklar.forEach(dayanak => {
      this.localValue.push(dayanak);
    });
    this.input();
  }

  converB64Data(item: any): string {
    return 'data:' + item.mimeType + ';base64,' + item.data;
  }

  viewDocument(item: any) {
    this.previewData = this.converB64Data(item);
    this.showPreview = true;
  }
}
